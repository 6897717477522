import React from "react";
import Header from "../../homeComponent/header";
import Footer from "../../homeComponent/footer";
import Pari from './imgJourney/Pari.png'
import Kapal from './imgJourney/kapal.png'
import Diving from './imgJourney/Diving.png'
export default function Journeys() {
  return (
    <>
    <Header/>
    <div className="diving">
      <img src={Diving}/>
    </div>
    <div className="pari">
      <img src={Pari}/>
    </div>
    <div className="appJourney">
      <div className="tittlePageJourney" data-aos="fade-up" data-aos-duration="1000">MAKE YOUR JOURNEY </div>
      <div className="listJourneys">
        <div className="journeys" data-aos="fade-up" data-aos-duration="1200" >
          <div className="tittleJourneys"> Tempat Tinggal </div>
          <div>
            Mahasiswa baru sering menghadapi dilema dalam menentukan tempat
            tinggal saat kuliah. Salah satu pilihan yang paling umum adalah
            menyewa kos. Kos biasanya merupakan pilihan yang paling terjangkau
            bagi mahasiswa, terutama jika dihuni bersama teman sekamar. Selain
            itu, kos juga cenderung lebih fleksibel dibandingkan pilihan
            lainnya, seperti asrama atau pondok. Ada juga pilihan untuk
            mengontrak rumah. Sisi positifnya adalah aturan rumah kontrakan
            lebih bebas dan kalian bisa “berisik” tanpa takut mengganggu penjaga
            kos. Tapi, kalian harus cermat memilih teman seperkontraka, jangan
            sampai kalian merasa tidak cocok saat sudah mengontrak. Kalian juga
            bisa loh melanjutkan kehidupan berasrama saat kuliah nanti. Asrama
            biasanya dikelola oleh universitas atau lembaga terkait, dan terdiri
            dari beberapa kamar yang dihuni oleh beberapa mahasiswa. Asrama
            umumnya lebih terorganisir dibandingkan kos dan memiliki beberapa
            peraturan tersendiri. Nah, pilihan lainnya adalah pondok. Pondok
            biasanya lebih terjangkau dari segi harga. Namun, pondok memiliki
            peraturan-peraturan dan kegiatan yang lebih banyak daripada asrama.
            Note: Cepat-cepatlah survei tempat kos untuk mendapatkan kualitas
            yang oke dan juga afforable. You must know, kos yang bagus tidak
            hanya diincar oleh mahasiswa baru angkatanmu saja, tapi mahasiswa
            tiap angkatan.
          </div>
        </div>
        <div className="journeys" data-aos="fade-up" data-aos-duration="1200" >
          <div className="tittleJourneys"> Organisasi </div>
          <div>
            {" "}
            Masa perkuliahan adalah masa eksplorasi diri dan salah satu
            sarananya adalah dengan mengikuti organisasi. Banyak sekali jenis
            organisasi yang bisa diikuti di kehidupan perkuliahan nanti, seperti
            Badan Eksekutif Mahasiswa(BEM) dan Himpunan Mahasiswa Jurusan(HIMA)
            yang mungkin cocok bagi yang ingin meningkatkan soft skill. Ada juga
            organisasi Unit Kegiatan Mahasiswa(UKM) yang dapat menjadi wadah
            untuk mengembangkan hobi atau minat, serta mencari teman yang
            memiliki minat yang sama. Ohya, meskipun menjadi aktif dalam
            organisasi merupakan pengalaman yang sangat bermanfaat, terutama
            dari segi pengembangan kepribadian dan keterampilan. Jangan sampai
            lupa bahwa menjadi mahasiswa itu tujuan utamanya adalah untuk
            belajar dan menyelesaikan studi dengan baik. Oleh karena itu, sangat
            penting untuk belajar memanajemen waktu. Jangan sampai lupa
            mengerjakan tugas karena terlalu aktif berorganisasi. Dosen tidak
            seperti guru yang masih mungkin untuk telat mengumpulkan tugas,
            kalau kamu telat mengumpulkan tugas ke dosen yah,... yaudah,
          </div> 
        </div>
        <div className="journeys" data-aos="fade-up" data-aos-duration="1200" >
          <div className="tittleJourneys">Sosial</div>
          <div>
            Yeah, relasi sosial adalah salah satu hal yang penting sebagai
            mahasiswa, baik selama masa perkuliahan ataupun untuk kedepannya.
            Terutama bagi mahasiswa baru yang masih awam soal kehidupan kampus.
            Mungkin kalian sering mendengar kalau pertemanan di masa kuliah
            tidak sama dengan saat SMA. Hmm... itu benar dan bahkan lebih sulit.
            Kalian jangan takut, hati-hatilah dengan perasaan fear of missing
            out dan pandai-pandailah memilih teman di awal karena bisa dibilang
            itu akan menentukan kehipuan sosial kalian ke depannya. Tentunya
            kalian tidak mau terjebak di circle yang toxic kan? Selain teman
            satu angkatan, aku saranin kalian juga berteman dengan kakak
            tingkat. Benefitnya tuh banyak banget. Misal kalian ada kesulitan
            buat urus administrasi, bisa tuh tanya ke kating. Kalian bisa juga
            tanya tentang karakteristik masing-masing dosen, termasuk
            dosen-dosen mana yang sebaiknya dihindari waktu isi SKS. Nah, yang
            paling penting nih. Kalian bisa dapet soal-soal terdahulu dari
            kating yang kemungkinannya buat muncul di kuis atau ujian tuh tinggi
            banget. Oh dan masih banyak lagi.
          </div>
        </div>
        <div className="journeys" data-aos="fade-up" data-aos-duration="1200" >
          <div className="tittleJourneys">Manajemen Keuangan </div>
          <div>
            Kamu akan ditantang untuk memanajemen keuanganmu dengan baik saat
            menjadi mahasiswa. Meskipun mungkin kamu merasa punya banyak uang
            pas masih di IC, tapi di kehidupan kuliah nanti uangmu (mungkin)
            akan terbatas. Jadi, kamu harus bisa ngatur uangmu dengan baik agar
            tidak cepat habis. Kalau kamu nggak ngatur keuanganmu dengan baik,
            bisa-bisa kamu nggak punya uang buat beli makan, apalagi healing.
            Nggak perlu khawatir kok(or not). Ada beberapa hal yang bisa kamu
            lakukan untuk ngatur keuanganmu dengan lebih baik di kampus, seperti
            membuat anggaran bulanan, tidak boros, mengurangi pengeluaran yang
            tidak perlu, dll. Tenang, nanti saat kamu sudah menjadi mahasiswa,
            seiring berjalannya waktu kamu akan lebih paham dan terampil dalam
            me-manage keuangan pribadi.
          </div> 
        </div>
        <div className="journeys" data-aos="fade-up" data-aos-duration="1200" >
          <div className="tittleJourneys"> Dosen </div>
          <div>
            Dosen itu...... ah sudahlah. Intinya, kalian pasti akan merasakan
            culture shock selama transisi diajar oleh guru dengan dosen. Tiap
            dosen itu unik. Ada dosen yang milenial, friendly, minim tugas dan
            selalu ngingetin deadline tugas, tapi ada juga dosen yang,.... yah
            kebalikannya, tugas bejibun + deadline nggak ngotak, over sensitif,
            nggak peduli sama nilai mahasiswanya, dan banyak sikap “unik”
            lainnya. Di masa kuliah nanti, kamu akan belajar lebih lanjut
            tentang keunikan dosen. Biasanya saat kalian ospek nanti akan ada
            materi lebih lanjut mengenai tata cara berinteraksi dengan dosen.
            Saat kuliah nanti, sangat disarankan untuk menanyai kating(kakak
            tingkat) kalian mengenai dosen yang akan mengajar, jadi kalian bisa
            mengantisipasi hal hal yang tidak dingginkan.
          </div>
        </div>
      </div>
    </div>
    <div className="kapal">
      <img src={Kapal}/>
    </div>
    <Footer/>
    </>
  );
}
